import breadcrumbComp from "../../common/breadcrumb-comp.vue";
// import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
//import recevingService from "./receive-srvice";
import FilterComp from "../../common/filter-comp.vue";
//import moment from "moment";
import utility from "@/shared/utility";
import DisplayTextData from "@/components/common/display-text-data.vue";
import ReadOnlyDialog from "@/components/common/read-only-dialog.vue";
import receiveSrvice from "./receive-srvice";
import commonApiService from "@/components/common/js/common-api-service";
import { bus } from "@/main";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      showSOPOButton: false,
      showProjectDetailsDialog: false,
      showStockRoom: false,
      validateType:"",
      includeClosed:false,
      projectDetailsObj: {
        proj_id: "",
        staging_loc: "",
        lpn: "",
      },
      dialogTitle: "",
      projectList: [],
      searchLPNRule: [(v) => (v || "").length <= 39 || "Max Length of 39 character", (v) => !!v || "Field is required", (value) => /^[0-9]{1,3}(?:-[0-9]{3})*$/.test(value) || "Enter a correct format: 000-000-000-000"],
      required: [(v) => !!v || "Field is required"],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    const { soObj, poObj } = this.actionList.reduce(
      (acc, item) => {
        if (item.SystemName === "SOReceiving") acc.soObj = item;
        if (item.SystemName === "POReceiving") acc.poObj = item;
        return acc;
      },
      { soObj: null, poObj: null }
    );
    if (soObj && !poObj) {
      this.onClickSalesOrder();
    } else if (poObj && !soObj) {
      this.onClickPurchase();
    }
  },
  computed: {},

  async mounted() {
    this.showSOPOButton = true;
  },
  methods: {
    //Show LPN Data on breadcrumb

    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    actionSetter(permission) {
      return utility.actionSetter(this.actionList, permission);
    },
    // onClick of SO
    async onClickSalesOrder() {
      this.validateType="SO"
      this.showSOPOButton = false;
      this.showProjectDetailsDialog = true;
      this.dialogTitle = "Set SO Parameters";
      let projectData = await receiveSrvice.getReceiveProject(
        "get",
        this.userId,
        "S"
      );
      this.projectList = projectData;
    },
    // onClick of PO
    async onClickPurchase() {
      this.validateType="PO"
      this.showSOPOButton = false;
      this.showProjectDetailsDialog = true;
      this.dialogTitle = "Set PO Parameters";
      let projectData = await receiveSrvice.getReceiveProject(
        "get",
        this.userId,
        "P"
      );
      this.projectList = projectData;
    },
    //Close project Details
    closeDialog() {
      this.showProjectDetailsDialog = false;
      this.showSOPOButton = true;
      this.dialogTitle = "";
      this.validateType=""
      this.projectList = [];
    },
    //on Change project
    onChangeProject() {
      if (this.$refs.projectForm.validate()) {
        this.showLPN = this.projectDetailsObj?.proj_id?.lpn_active;
      }
    },
    async onClickValidate() {
      if (this.$refs.projectForm.validate() && this.$refs.lpnForm.validate()) {
        let lpnObj = {
          UserId: this.userId,
          bin: this.projectDetailsObj?.staging_loc,
          lpn: this.projectDetailsObj?.lpn,
          proj_key: this.projectDetailsObj?.proj_id?.project_id,
        };
        let res=await receiveSrvice.postValidateLPNStage("post",lpnObj,false)
        if(res[0]?.stage_bin_key){
          this.$router.push(`/receiving-list/${this.validateType}`);
          bus.$emit("receiveDetails", lpnObj);
          
        }         

      }

    },
      async getLPNNumber() {
        let lpnData = await commonApiService.getNextAvailableLPN("get", this.userId);
        if (lpnData.message !== "NA") {
            this.projectDetailsObj.lpn = lpnData[0]?.lpn;
        }
    },

    lpnNumFormat(event, num) {
        this.projectDetailsObj = utility.formatLPNNumber(event, num);
    }
  },
  components: {
    breadcrumbComp,
    FilterComp,
    DisplayTextData,
    ReadOnlyDialog,
  },
};
