import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  getInitalList(call, userId) {
    return this.getData(`rv/discrep_resolve_init?user_id=${userId}`, call);
  },
  postSearch(call, obj, showMessage) {
    return this.getData(`rv/discrep_resolve_search`, call, obj, showMessage);
  },
 
  getDiscrepancyDetails(call,line_id){
    return this.getData(`rv/discrep_resolve_load_detail?line_id=${line_id}`, call);
  },

  getResolutionDetails(call, obj, showMessage) {
    return this.getData(`rv/discrep_get_resolutions`, call, obj, showMessage);
  },
  postResolveUpdateAction(call, obj, showMessage) {
    return this.getData(`rv/discrep_resolve_update`, call, obj, showMessage);
  },

  postOrderSearch(call, obj, showMessage) {
    return this.getData(`rv/discrep_resolve_order_search`, call, obj, showMessage);
  },
  getLineItems(call, obj, showMessage) {
    return this.getData(`rv/discrep_resolve_get_avail_lines`, call, obj, showMessage);
  },

  // Receiving Page

  getReceiveProject(call,userId,type){
    return this.getData(`rv/receiving_projects_get?UserId=${userId}&po_so=${type}`, call);
  },

  postValidateLPNStage(call,obj,showMessage){
    return this.getData("rv//receiving_stage_lpn_val",call,obj,showMessage)
  },


  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res?.data?.body?.message != undefined || res?.data?.body?.message != null) {
      if (!res?.data?.body?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res?.data?.body?.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res.data.body.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: this.isJson(res?.data?.body?.message) ? JSON.parse(res?.data?.body?.message).Message : "" || res?.data?.body?.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        if (this.isJson(res?.data?.body?.message)) return JSON.parse(res?.data?.body?.message);
        else return `{"Status":200,"Message":"${res?.data?.body?.message}"}`;
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    } else if (res?.data?.body?.message == null) {
      if (res?.data?.body !== null) {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return res?.data?.body;
      } else {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      }
    } else {
      if (!res?.data?.message) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(res?.data.message),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = res?.data?.message;
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: "success",
            isShow: true,
            message: res.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
        }
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: "error",
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit("ConfigModule/Alert", Alert);
        this.errorMessage = err.message;
        return {};
      }
    }
  },
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
};
